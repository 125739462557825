<template>
  <div class="mytasks_iframe_wrap">
      <iframe :style="{height:`${iframeHeight}px`}" class="mytasks_iframe" :src="`//${host}/mytasks/index`" frameborder="0"></iframe>
  </div>
</template>

<script>
import { addListenerMessage } from '@/utils/util'

export default {
  components: {
  },
  data(){
    return{
       params:{
           ...this.$route.params
       },
        iframeHeight: 0,
        host: this.VUE_APP_TRANSLATE_URL
    }
  },
  methods:{},
  created(){
    this.iframeHeight = window.innerHeight - 69
  },
  mounted(){
    addListenerMessage(this);
  },
}
</script>

<style lang='less'>

.mytasks_iframe_wrap {
     width: 100%;
    height: 100%;
    overflow: auto;
}

.mytasks_iframe {
    width: 100%;
    // height: 100%;
    height: 500px;
   overflow: auto;
}

</style>
