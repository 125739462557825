var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mytasks_iframe_wrap" }, [
    _c("iframe", {
      staticClass: "mytasks_iframe",
      style: { height: `${_vm.iframeHeight}px` },
      attrs: { src: `//${_vm.host}/mytasks/index`, frameborder: "0" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }